const popover_options = {html: true, trigger: 'hover', placement: 'auto', container: 'body', sanitize: false};

function load_ajax_content(e, url) {
    if (!$(e).hasClass('no-loader')) {
        let loading_text = '';
        if (!$(e).hasClass('no-loader')) {
            if (!$(e).hasClass('no-loading-text')) {
                loading_text = 'Loading ' + ($(e).data('context') || 'content');
            }
            let link = $(e).data('link') || 'javascript:void(0)';
            $(e).html('<h5><i class="fa-solid fa-spinner fa-spin ajax-loading"></i><a href="' + link + '">' + loading_text + '..</a></h5>');
        }
    }

    let url_val  = url || $(e).data('url');

    $.get(url_val)
        .done(function (response) {
            $(e).css('display', 'none');
            if (response.trim() != '') {
                $(e).html(response);
                $(e).fadeIn();
                $(".add-ajax-popover").popover(popover_options);
            }
        })
        .fail(function (response) {

            if (!$(e).hasClass('no-error')) {
                let context = $(e).data('context') || 'remote content';
                let link = '';
                if(response.status === 403)
                {
                     $(e).html('<p class="text-danger text-bold pad-lft"><a class="add-tooltip no-permission" disabled title="Forbidden"><i class="fa-solid fa-triangle-exclamation"></i> Could not load ' + context + '</a></p>');
                }
                else{
                    link = $(e).data('link') || 'javascript:void(0)';
                    $(e).html('<p class="text-danger text-bold pad-lft"><a href="' + link + '"><i class="fa-solid fa-triangle-exclamation"></i> Could not load ' + context + '</a></p>');
                }


            }
        })
        .always(function () {
            $(e).removeData('url');

            // happens a lot in aside, need to reset the nanoscroll
            if($(e).parents('.nano.has-scrollbar').length){
                $(e).parents('.nano.has-scrollbar').nanoScroller();
            }
        });
}

function initCounter(){
    $(".counter").each(function () {
        let $e = $(this);
        $e.html('<span class="fa-solid fa-spinner fa-spin"></span>');
        $.get($(this).attr("data-json-url"), function (data) {
            $e.html(data['meta']['total_elements']);
            let list = [];
            for (let elt in data['elements']) {
                // eslint-disable-next-line security/detect-object-injection
                if (data['elements'][elt].name) {
                    // eslint-disable-next-line security/detect-object-injection
                    list.push(data['elements'][elt].name.unicodeRepresentation);
                }
            }
            if ((data['elements'] || []).length < data['meta']['total_elements']) list.push('...');
            if ((data['elements'] || []).length > 0 || (!(data['elements'] || []).length && data['meta']['total_elements'] > 0)) {
                $e.parents('.badge').attr('title', list.join("\n")).addClass('badge-success');
            } else {
                $e.parents('.badge').addClass('badge-dark');
            }
        }).fail(function () {
            $e.parents('.badge').addClass('badge-danger');
            $e.html('<span class="fa-solid fa-triangle-exclamation"></span>');
        });
    });
}

nifty.document.ready(function () {

    initCounter();

    $(".add-ajax-content").each(function () {
        let $e = $(this);
        load_ajax_content($e);
    });

    $(".add-ajax-content-when-collapsed").each(function () {
        let $e = $(this);
        $e.on('show.bs.collapse', function(){
            load_ajax_content($e);
        });
    });


    $(".add-ajax-popover").popover(popover_options);
});

nifty.body.on('mouseover', '.add-ajax-popover', function () {
    var e = $(this);

    if (e.data('title') == undefined) {
        // set the title, so we don't get here again.
        e.data('title', e.text());

        // retrieve the real content for this popover, from location set in data-url
        $.get(e.data('url'))
            .done(function (response) {
                // set the ajax-content as content for the popover
                e.data('content', response);
                e.attr('title', $(response).filter('.popover-title-content').html());

            })
            .fail(function () {
                e.data('content', '<div class="alert alert-danger"><i class="fa-solid fa-triangle-exclamation"></i> Could not load remote content</div>');
                e.removeData('title');
            })
            .always(function () {
                // replace the popover
                e.popover('destroy');
                e.popover(popover_options);

                // check that we're still hovering over the preview, and if so show the popover
                if (e.is(':hover')) {
                    e.popover('show');
                }
            });
    }
});

window.load_ajax_content = load_ajax_content