nifty.document.ready(function () {

    $('.datetimepicker, .datetimerangepicker').each(function () {
        let date = $(this).val();
        let dateFormat = $(this).data('date-format');
        let isValid = moment(date).isValid();
        if (isValid) {
            let newDate = moment(date).format(dateFormat);
            if (date !== newDate)
                $(this).val(newDate)
        }
    });

    $('.datetimerangepicker-trigger').click(function () {
            $(this).parents(".input-group").find(".datetimerangepicker").click();
        }
    );

    $('.datetimerangepicker').each(function () {
        let parentElSelector = $(this).closest('.modal').length ? $(this).closest('.modal')[0] : 'body'

        $(this).daterangepicker({
            autoUpdateInput: false,
            timePicker: true,
            timePicker24Hour: true,
            parentEl: parentElSelector,
            timePickerIncrement: 1,
            cancelClass: "btn-danger",
            maxDate: moment().add(10, 'years'),
            locale: {
                "format": $(this).data('date-format'),
                "separator": " - ",
                "applyLabel": "Apply",
                "cancelLabel": "Cancel",
                "fromLabel": "From",
                "toLabel": "To",
                "customRangeLabel": "Custom",
                "weekLabel": "W",
                "daysOfWeek": moment.weekdaysShort(),
                "monthNames": moment.months(),
                "firstDay": 1,
            },
            startDate: $("#id_" + $(this).attr('data-startdate-field')).val() ? $("#id_" + $(this).attr('data-startdate-field')).val() : moment().startOf('day'),
            endDate: $("#id_" + $(this).attr('data-enddate-field')).val() ? $("#id_" + $(this).attr('data-startdate-field')).val() : moment().endOf('day'),
            maxSpan: {days: $(this).attr('data-max-days-range')}
        })
    });

    $('.datetimerangepicker').on('show.daterangepicker', function (ev, picker) {
        let startDateVal = $("#id_" + $(this).attr('data-startdate-field')).val()
        let endDateVal = $("#id_" + $(this).attr('data-enddate-field')).val()

        if(startDateVal)
            picker.setStartDate($("#id_" + $(this).attr('data-startdate-field')).val())

        if(endDateVal)
            picker.setEndDate($("#id_" + $(this).attr('data-enddate-field')).val())

        if(startDateVal || endDateVal)
            picker.updateView();

    })

    $('.datetimerangepicker').on('apply.daterangepicker', function (ev, picker) {
        $("#id_" + $(this).attr('data-startdate-field')).val(picker.startDate.format($(this).attr('data-date-format')));
        $("#id_" + $(this).attr('data-enddate-field')).val(picker.endDate.format($(this).attr('data-date-format')));
    });

    /* Date picker */
    $('.datepicker').each(function () {
        let parentElSelector = $(this).closest('.modal').length ? $(this).closest('.modal')[0] : 'body'
        $(this).daterangepicker({
            autoUpdateInput: false,
            cancelClass: "btn-danger",
            singleDatePicker: true,
            parentEl: parentElSelector,
            maxDate: moment().add(10, 'years'),
            locale: {
                "format": $(this).data('date-format'),
                "separator": " - ",
                "applyLabel": "Apply",
                "cancelLabel": "Cancel",
                "fromLabel": "From",
                "toLabel": "To",
                "customRangeLabel": "Custom",
                "weekLabel": "W",
                "daysOfWeek": moment.weekdaysShort(),
                "monthNames": moment.months(),
                "firstDay": 1,
            }
        })
    });

    $('.datepicker-trigger').click(function () {
            $(this).parents(".input-group").find(".datepicker").click();
        }
    );


    $('.datepicker').on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format($(this).attr('data-date-format')));
    });

    /* Month picker */
    $('.monthpicker').datepicker({
        startView: 1,
        minViewMode: 1
    });

    $('.monthpicker-trigger').click(function () {
        $(this).parents(".input-group").find(".monthpicker").focus();
    });

    /* Date time picker */
    $('.datetimepicker').each(function(){
        // if you don't select the element $(this).closest('.modal')[0] , the library will apply a jquery selector and get the first element as parent
        let parentElSelector = $(this).closest('.modal').length ? $(this).closest('.modal')[0] : 'body'
        $(this).daterangepicker(
        {
            autoUpdateInput: false,
            cancelClass: "btn-danger",
            singleDatePicker: true,
            showDropdowns: true,
            parentEl: parentElSelector,
            timePicker: true,
            timePicker24Hour: true,
            timePickerIncrement: 1,
            maxDate: moment().add(10, 'years'),
            locale: {
                "format": $(this).data('date-format'),
                "separator": " - ",
                "applyLabel": "Apply",
                "cancelLabel": "Cancel",
                "fromLabel": "From",
                "toLabel": "To",
                "customRangeLabel": "Custom",
                "weekLabel": "W",
                "daysOfWeek": moment.weekdaysShort(),
                "monthNames": moment.months(),
                "firstDay": 1,
            }
        }
    )});

    $('.datetimepicker').on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format($(this).attr('data-date-format')));
    });

    $('.datetimepicker-trigger').click(function () {
            $(this).parents(".input-group").find(".datetimepicker").click();
        }
    );
    $(document).keyup(function (e) {
        if (e.keyCode == 27) {
            $('.daterangepicker').css('display', 'none');
        }
    });
});

