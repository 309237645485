Number.prototype.padLeft = function (n, str) {
    return Array(n - String(this).length + 1).join(str || '0') + this;
};

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (searchString, position) {
        var subjectString = this.toString();
        if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
            position = subjectString.length;
        }
        position -= searchString.length;
        var lastIndex = subjectString.indexOf(searchString, position);
        return lastIndex !== -1 && lastIndex === position;
    };
}

$.fn.setCursorPosition = function (pos) {
    this.each(function (index, elem) {
        if (elem.setSelectionRange) {
            elem.setSelectionRange(pos, pos);
        } else if (elem.createTextRange) {
            var range = elem.createTextRange();
            range.collapse(true);
            range.moveEnd('character', pos);
            range.moveStart('character', pos);
            range.select();
        }
    });
    return this;
};

window.sortByText = function (a, b) {
    if ($.trim($(a).text()) > $.trim($(b).text())) {
        return 1;
    } else {
        if ($.trim($(a).text()) < $.trim($(b).text())) {
            return -1;
        } else {
            return 0;
        }
    }
}

// Select/Un-select all

nifty.document.ready(function () {
    update_checkall_state();
    $('input:checkbox.checkall').click(function () {
        let checkAll = false;
        if ($(this).is(':checked')) {
            checkAll = true;
        }
        $(this).closest('fieldset').find('.form-group .control-group .magic-checkbox').each(function () {
            $(this).prop('checked', checkAll);
        });
        update_checkall_state();
    })
    $('input[name="permissions"]:checkbox').click(function () {
        update_checkall_state();
    })
});


function update_checkall_state() {
    $('.checkall').each(function () {
        var $all = $(this).parents('div.checkbox');
        var i = 0;
        var j = 0;
        $(this).closest('fieldset').find('.form-group .control-group .magic-checkbox').each(function (ii, v) {
            i++;
            if ($(v).is(':checked')) {
                j++;
            }
        });
        if (j === i) {
            // All checked
            $(this).prop('checked', true);
            $all.css('opacity', ' 1');
            $all.css('filter', 'alpha(opacity=100)');
        } else {
            if (j > 0) {
                // Not all checked
                $(this).prop('checked', false);
                $all.css('opacity', ' 0.5');
                $all.css('filter', 'alpha(opacity=50)');
            } else {
                // None checked
                $(this).prop('checked', false);
                $all.css('opacity', ' 1');
                $all.css('filter', 'alpha(opacity=100)');
            }
        }
    });
}

window.csrfSafeMethod = function(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}