nifty.document.ready(function(){
    // define hover behavior on ipad => clicking on sm menu icon will behave like hover
    if(navigator.platform.indexOf("iPad") == -1)
        return;

    $('#mainnav-menu > li > a span').click(function(evt){
        if($('#container').hasClass('mainnav-sm') && !$(this).parents('a').first().hasClass('hover')) {
            evt.preventDefault();
            evt.stopImmediatePropagation();
        }
    });

    $('#mainnav-menu > li > a').click(function(evt){
       if($('#container').hasClass('mainnav-sm') && !$(this).hasClass('hover')){
           evt.preventDefault();

           // we remove the hover and active classes on the other links
           $.each($('#mainnav-menu > li > a, #mainnav-menu > li'), function(key, el){
              $(el).removeClass('hover');
              $(el).removeClass('active');
              $(el).removeClass('activesm');
           });

           // simulate an hover behavior
           $(this).popover('show');
           $('#mainnav .menu-popover a').click(function(evt){
             evt.preventDefault();
             window.location = $(this).attr('href');
           });
       }
    });


});
