// to be put before so bootstrap overrides all plugins
import 'jquery-ui/dist/jquery-ui'

// bootstrap related
import "bootstrap"
import "bootstrap-toggle"
import "../../vendor/js/bootstrap-toggle-2-2-2-fix"
import "bootstrap-select"
import "bootstrap-slider"
import "bootstrap-datepicker"

// jquery related
import 'jquery-form'
import "daterangepicker"
import "sticky-table-headers"
import 'print-this'

import "fastclick"
import "parsleyjs"
import "parsleyjs/src/extra/validator/date"
import "parsleyjs/src/extra/validator/dateiso"
import MobileDetect from 'mobile-detect'

import "../../vendor/js/nifty-2.2-to-2.6"
import "../../vendor/js/nifty-2.9"
import "../../vendor/js/eurid-jquery-countup"
import "../../vendor/js/jquery.formset"
import "../../vendor/js/jquery.autoHeight"

import "./core/ajax"
import "./core/datepicker"
import "./core/parsleyErrorsAndValidators"
import "./core/fields"
import "./core/formset"
import "./core/iframe"
import "./core/modals"
import "./core/nav"
import "./core/omni"
import "./utils"

import '../../vendor/css/vendor.css'
import '../css/eurid.css'

$(document).ready(function () {

    if ($('div#aside').length) {
        /* Add aside-in required class ton main container if we have a sidebar */
        if (window.innerWidth > 760) {
            $('div#container').addClass('aside-in');
        }
    }

    $("#mainnav-menu ul").each(function () {
        /* Expands the menu which contains the active menu item */
        if ($(this).find(".active-link").length > 0) {
            $(this).addClass('in');
        }
    });

    $(".selpicker").each(function () {
        let livesearch = $(this).find('option').length > 10;
        let mobile = false;
        if (($(window).width() <= 767) && ($(this).attr('data-mobile') == "true")) {
            mobile = true;
        }
        let selectStyle = $(this).attr('data-style') || "";
        $(this).selectpicker({noneSelectedText: '', liveSearch: livesearch, 'style': selectStyle, mobile: mobile});
    });

    $('.input-slider').each(function () {
        $(this).bootstrapSlider();
    });

    $('.sortable-list').each(function () {
        $(this).sortable();
    });

    // Disable everything that's inside a .no-permission or .disabled
    setTimeout("$('.no-permission .nrp-disabled').off('click')", 500);
    $('.no-permission .no-permission, .no-permission .disabled').tooltip('disable');

    $('.fade-out-text').click(function () {
        $(this).removeClass('fade-out-text');
    });

    $('.fade-out-text-small').click(function () {
        $(this).removeClass('fade-out-text-small');
    });

    $("#per_page-select").change(function () {
        window.location = $(this).val();
    });

    if (location.hash !== '') {
        $('.nav-tabs a[href="' + location.hash.replace('tab_', '') + '"]').tab('show');
    } else {
        $('.nav-tabs:not(.static) a:first').tab('show');
    }

    $('.nav-tabs a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        window.location.hash = 'tab_' + e.target.hash.substr(1);
        return false;
    });

    window.addEventListener("hashchange", function () {
        if (window.location.hash.startsWith('#tab_'))
            $('li:not(.active) a[href="' + location.hash.replace('#tab_', '#') + '"][data-toggle="tab"]').tab('show');
    }, false);
});

$('[data-expand]').on("click", function () {
    let $current_obj = $(this);
    let more_caption = $current_obj.data('expand-caption') || 'More';
    let less_caption = $current_obj.data('collapse-caption') || 'Less';
    let animate = $current_obj.data('animate') || 'yes';
    let animation = animate === 'yes';
    let elem_to_expand = $current_obj.attr("data-expand");
    if ($("#" + elem_to_expand).css('display') == 'none') {
        $("#" + elem_to_expand).slideDown();
        $current_obj.html(less_caption);
    } else {
        if (animation) {
            $("#" + elem_to_expand).slideUp('fadeOut');
        } else {
            $("#" + elem_to_expand).hide();
        }
        $current_obj.html(more_caption);
    }
});

$('span[data-expand]:not(.header-toggler)').on('click', function () {
    var $this = $(this);
    var $tr = $this.closest('tr');

    var selectedStyle = 'border-left-style: solid; border-left-color: gray';
    var neutralStyle = 'border-left-style: none;';
    if ($this.text() === $this.attr('data-collapse-caption')) {
        $tr.attr('style', selectedStyle);
    } else {
        $tr.attr('style', neutralStyle);
    }
});


$('body').tooltip({
    selector: '.add-tooltip',
    container: 'body',
    // trigger: 'click',
});

$('body').tooltip({
    trigger: 'manual',
    selector: '.add-static-tooltip',
});

nifty.document.ready(function () {
    $('.add-static-tooltip').tooltip('show');
});

nifty.document.ready(function () {
    $(".phone-quality-loading").html('<span class="fa-solid fa-rotate fa-spin"></span>');
    $(".phone-quality-score").each(
        function () {
            let e = $(this);
            let phone = e.data('contact-phone');
            let country = e.data('contact-country');

            if (phone !== null && phone !== undefined) {
                $.ajax({
                    url: e.data('target-url') + '?phone=' + encodeURIComponent(phone || '') + '&country=' + encodeURIComponent(country || ''),
                    method: 'GET',
                    success: function(data){

                        if (data?.['result']) {

                            let defaults = {
                                "phone_possible": true,
                                "phone_valid": true,
                                "eligible_country": true,
                                "phone_parse_error": false,
                                "phone_country_match": true,
                            };
                            let text = '';

                            for (let item in data['result']) {
                                // eslint-disable-next-line security/detect-object-injection
                                if (item in defaults && defaults[item] !== data['result'][item]) {
                                    let span = $(".phone_quality_" + item);
                                    text += span.html() + ' ';
                                }
                            }

                            if (text) {
                                text = text.replace(/{phone_country}/g, data['result']['phone_country']);
                                e.addClass('fa-triangle-exclamation');
                                e.addClass('text-danger');
                            } else {
                                e.addClass('fa-check');
                                e.addClass('text-success');
                                text = 'Phone validation passed.'
                            }

                            e.attr('title', `Overall score ${data?.['result']?.['score']}; ${text}`);
                            e.addClass('add-tooltip');
                            e.addClass('fa');

                        }
                        else {
                            e.addClass('fa-triangle-exclamation');
                            e.addClass('text-danger');
                            e.text('Could not validate phone number')
                        }
                    },
                    error: function(){
                        e.addClass('text-danger')
                        e.text('Error occurred.')
                    },
                    complete: function(){
                        $(".phone-quality-loading").hide(500, function(){
                            e.show()
                        });
                    }
                })
            }
        }
    );
});


$("#action-form-registrar .dropdown-menu a:not(.no-permission)").on('click', function () {
    let action = $(this).data('action');
    $('#action').val(action);
    if (action !== 'unrestrict' && action !== 'reactivate' && action !== 'bulk-trigger-dyn-update') {
        $('#action-form-registrar').submit();
    }
});

$('.hide-help-text .help-block').each(function () {
    $(this).siblings('label').css('width', '100%').append('<i class="fa-solid fa-circle-question text-right pull-right add-tooltip" title="' + $(this).text() + '"></i>');
});

nifty.document.ready(function () {
    $('.reveal').addClass('add-tooltip').attr('title', 'Click to reveal content');
    let aside_container = $('#aside-container');
    if (aside_container.length !== 0) {
        $('#navbar li:has(a.aside-toggle)').show();
    }
});

$('body').on('click', '.reveal', function () {
    $(this).removeClass('reveal').addClass('revealed').removeClass('add-tooltip').tooltip('disable');
});

$('body').on('click', '.revealed', function () {
    $(this).removeClass('revealed').addClass('reveal').addClass('add-tooltip').tooltip('enable');
});

$("body").on('click', "#id_removeUnrestrict", function () {
    $("#id_unRestrictedUntil").prop('disabled', this.checked);
});

$('.menu-link').click(function (event) {
    if (event.target.nodeName != 'I') {
        event.stopImmediatePropagation();
        window.location = $(this).attr('href');
    }
});

(function ($) {
    $.fn.goTo = function () {
        $('html, body').animate({
            scrollTop: $(this).offset().top + 'px'
        }, 'fast');
        return this; // for chaining...
    }
})(jQuery);

$('.mobile_view').each(function () {
    let md = new MobileDetect(window.navigator.userAgent);
    if (!md.mobile()) {
        $(this).hide();
    }
});

$('.tablet_view').each(function () {
    let md = new MobileDetect(window.navigator.userAgent);
    if (!md.tablet()) {
        $(this).hide();
    }
});

$('.mobile_tablet_view').each(function () {
    let md = new MobileDetect(window.navigator.userAgent);
    if (!md.tablet() && !md.mobile()) {
        $(this).hide();
    }
});

$('.desktop_view').each(function () {
    let md = new MobileDetect(window.navigator.userAgent);
    if (md.tablet() || md.mobile()) {
        $(this).hide();
    }
});

$(document).ready(function () {
    $('.collapse-chevron').each(function () {
        var this_el = $(this);
        if ($(this).next('.collapse').length) {
            $(this).next('.collapse').on('show.bs.collapse', function (e) {
                if ($(this).is(e.target)) {
                    this_el.find('i[class*="fa-chevron"]').toggleClass('fa-rotate-180');
                }
            });
            $(this).next('.collapse').on('hide.bs.collapse', function (e) {
                if ($(this).is(e.target)) {
                    this_el.find('i[class*="fa-chevron"]').toggleClass('fa-rotate-180');
                }
            });
        } else {
            $(this).next('form').find('.collapse').on('show.bs.collapse', function (e) {
                if ($(this).is(e.target)) {
                    this_el.find('i[class*="fa-chevron"]').toggleClass('fa-rotate-180');
                }
            });
            $(this).next('form').find('.collapse').on('hide.bs.collapse', function (e) {
                if ($(this).is(e.target)) {
                    this_el.find('i[class*="fa-chevron"]').toggleClass('fa-rotate-180');
                }
            });
        }
    });
});

nifty.document.ready(function () {
    $('form.disable-after-submit').each(function () {
        $(this).submit(function () {
            var is_valid_parsley = true;
            if ($(this).hasClass('parsley'))
                is_valid_parsley = $(this).parsley().isValid();

            if (is_valid_parsley) {
                // disable after one click
                $(this).find('button[type="submit"]').attr('disabled', 'true');
                $(this).find('button[type="submit"]').removeAttr('href');
                $(this).find('button[type="submit"]').attr('type', 'button');
            }
        });
    });
});

nifty.document.ready(function () {
    $('.count-up').each(function () {
        $(this).CountUp("start", $(this).data('start-val') || 0, $(this).data('end-val'));
    });
});


nifty.document.ready(function () {

    let nightModeSwitch = $('#night-mode-switch');

    if ($('body').hasClass('night')) {
        setDarkHighChartTheme(true);
    }

    nightModeSwitch.change(function () {
        if ($(this).prop('checked')) {
            $('body').addClass('night');
            setDarkHighChartTheme(true);
            $.ajax({
                url: '/theme-light',
                data: {'night': true}
            })
        } else {
            $('body').removeClass('night');
            setDarkHighChartTheme(false);
            $.ajax({
                url: '/theme-light',
                data: {'night': false}
            })
        }
    });
});

// setting night theme

const defaultHighchartTheme = $.extend(true, {}, Highcharts.getOptions(), {});
window.defaultHighchartTheme = defaultHighchartTheme;

window.darkUnicaHighchartTheme = {
    colors: ["#2b908f", "#90ee7e", "#f45b5b", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee",
        "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],
    chart: {
        backgroundColor: {
            linearGradient: {x1: 0, y1: 0, x2: 1, y2: 1},
            stops: [
                [0, '#2a2a2b'],
                [1, '#3e3e40']
            ]
        },
        style: {
            fontFamily: "'Unica One', sans-serif"
        },
        plotBorderColor: '#606063'
    },
    title: {
        style: {
            color: '#E0E0E3',
            textTransform: 'uppercase',
            fontSize: '20px'
        }
    },
    subtitle: {
        style: {
            color: '#E0E0E3',
            textTransform: 'uppercase'
        }
    },
    xAxis: {
        gridLineColor: '#707073',
        labels: {
            style: {
                color: '#E0E0E3'
            }
        },
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        title: {
            style: {
                color: '#A0A0A3'

            }
        }
    },
    yAxis: {
        gridLineColor: '#707073',
        labels: {
            style: {
                color: '#E0E0E3'
            }
        },
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        tickWidth: 1,
        title: {
            style: {
                color: '#A0A0A3'
            }
        }
    },
    tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
            color: '#F0F0F0'
        }
    },
    plotOptions: {
        series: {
            dataLabels: {
                color: '#B0B0B3'
            },
            marker: {
                lineColor: '#333'
            }
        },
        boxplot: {
            fillColor: '#505053'
        },
        candlestick: {
            lineColor: 'white'
        },
        errorbar: {
            color: 'white'
        }
    },
    legend: {
        itemStyle: {
            color: '#E0E0E3'
        },
        itemHoverStyle: {
            color: '#FFF'
        },
        itemHiddenStyle: {
            color: '#606063'
        }
    },
    credits: {
        style: {
            color: '#666'
        }
    },
    labels: {
        style: {
            color: '#707073'
        }
    },

    drilldown: {
        activeAxisLabelStyle: {
            color: '#F0F0F3'
        },
        activeDataLabelStyle: {
            color: '#F0F0F3'
        }
    },

    navigation: {
        buttonOptions: {
            symbolStroke: '#DDDDDD',
            theme: {
                fill: '#505053'
            }
        }
    },

    // scroll charts
    rangeSelector: {
        buttonTheme: {
            fill: '#505053',
            stroke: '#000000',
            style: {
                color: '#CCC'
            },
            states: {
                hover: {
                    fill: '#707073',
                    stroke: '#000000',
                    style: {
                        color: 'white'
                    }
                },
                select: {
                    fill: '#000003',
                    stroke: '#000000',
                    style: {
                        color: 'white'
                    }
                }
            }
        },
        inputBoxBorderColor: '#505053',
        inputStyle: {
            backgroundColor: '#333',
            color: 'silver'
        },
        labelStyle: {
            color: 'silver'
        }
    },

    navigator: {
        handles: {
            backgroundColor: '#666',
            borderColor: '#AAA'
        },
        outlineColor: '#CCC',
        maskFill: 'rgba(255,255,255,0.1)',
        series: {
            color: '#7798BF',
            lineColor: '#A6C7ED'
        },
        xAxis: {
            gridLineColor: '#505053'
        }
    },

    scrollbar: {
        barBackgroundColor: '#808083',
        barBorderColor: '#808083',
        buttonArrowColor: '#CCC',
        buttonBackgroundColor: '#606063',
        buttonBorderColor: '#606063',
        rifleColor: '#FFF',
        trackBackgroundColor: '#404043',
        trackBorderColor: '#404043'
    },

    // special colors for some of the
    legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
    background2: '#505053',
    dataLabelsColor: '#B0B0B3',
    textColor: '#C0C0C0',
    contrastTextColor: '#F0F0F3',
    maskColor: 'rgba(255,255,255,0.3)'
};

function resetOptions() {
    // Fortunately, Highcharts returns the reference to defaultOptions itself
    // We can manipulate this and delete all the properties
    if (Highcharts === undefined)
        return;

    let defaultOptions = Highcharts.getOptions();
    for (let prop in defaultOptions) {
        // eslint-disable-next-line security/detect-object-injection
        if (typeof defaultOptions[prop] !== 'function') delete defaultOptions[prop];
    }
    // Fall back to the defaults that we captured initially, this resets the theme
    Highcharts.setOptions(defaultHighchartTheme);
}

function setDarkHighChartTheme(night) {

    if (Highcharts === undefined)
        return;

    if (night) {
        resetOptions();
        Highcharts.setOptions(window.darkUnicaHighchartTheme);
    } else {
        resetOptions();
    }

    // redraw all highcharts
    Highcharts.charts.forEach(function (chart) {
        if (chart) {

            let renderTo = chart.renderTo.id;
            let options = chart.userOptions;

            chart.destroy();

            if (night) {
                $('#' + renderTo).highcharts(options);
            } else {
                $('#' + renderTo).highcharts(options);
            }
        }
    })
}

nifty.document.on("click", ".more-pagination", function () {
    let disabledClass = "nrp-disabled";
    let $this = $(this);

    if (!$this.hasClass(disabledClass)) {

        $this.find('span').css('display', 'none');
        $this.find('i').removeClass('fa-plus');
        $this.find('i').addClass('fa-spinner fa-pulse');
        $this.toggleClass(disabledClass);
        let searchParams = $this.data('urlQs');
        $($this.data('uiAlert')).html('');
        $.ajax({
            url: $this.data('url') + searchParams.toString(),
            type: $this.data('method'),
            success: function (data) {
                if ($this.data('uiElementToReplace')) {
                    let el = $($this.data('uiElementToReplace'));
                    el.fadeOut(500, function () {
                        el.replaceWith(data)
                    }).fadeIn();
                }
                window.top.$('body').trigger('more-keyset-pagination', {});
            },
            error: function (err) {
                console.error(err);
                $($this.data('uiAlert')).html('');
                if (err.responseJSON.requestId) {
                    $($this.data('uiAlert')).append(`<div class="alert alert-danger" style="max-width: 150px; margin: 0 auto;">${err.responseJSON.error}<br/>${err.responseJSON.requestIdText}</div>`);
                } else {
                    $($this.data('uiAlert')).append(`<div class="alert alert-danger" style="max-width: 150px; margin: 0 auto;">${err.responseJSON.error}</div>`);
                }
                $this.removeClass(disabledClass);
                $this.find('span').fadeIn();
                $this.find('i').addClass('fa-plus');
                $this.find('i').removeClass('fa-spinner fa-pulse');
            }
        })
    }
});