function doIframe() {
    let o = document.getElementsByTagName('iframe');
    for (let i = 0; i < o.length; i++) {
        if (/\bautoHeight\b/.test(o[i].className)) {
            setHeight(o[i]);
            addEvent(o[i], 'load', doIframe);
        }
    }
}

function setHeight(e) {
    if (e.contentDocument) {
        e.height = e.contentDocument.body.offsetHeight;// + 35;
    } else {
        e.height = e.contentWindow.document.body.scrollHeight;
    }
}

function addEvent(obj, evType, fn) {
    if (obj.addEventListener) {
        obj.addEventListener(evType, fn, false);
        return true;
    } else if (obj.attachEvent) {
        return obj.attachEvent("on" + evType, fn);
    } else {
        return false;
    }
}

if (document.getElementById && document.createTextNode) {
    addEvent(window, 'load', doIframe);
}

export {doIframe}