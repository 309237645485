/**
 * Created by hugos on 11/30/17.
 */
$(document).ready(function(){
    $.each($('div.toggle[data-toggle="toggle"]'), function(ind, el){
        if(!$(el).hasClass('off'))
        {
            $(el).find('input[type="checkbox"]').attr('value', "True");
        }
        else{
            $(el).find('input[type="checkbox"]').attr('value', "False");
        }
    });
    $('div.toggle[data-toggle="toggle"]').change(function(){
        if(!$(this).hasClass('off'))
        {
            $(this).find('input[type="checkbox"]').attr('value', "True");
        }
        else{
            $(this).find('input[type="checkbox"]').attr('value', "False");
        }
    });
});