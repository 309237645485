nifty.document.ready(function () {
  $("#omni-search form").on('submit', function (event) {

    let $form = $(this);

    let $input = $form.find("input[name='omni-query']");
    let query = $input.val();

    if ($form.attr('data-category') && typeof query != "undefined" ){
      switch ($form.attr('data-category')) {
        case "Domain":
          event.preventDefault();
          window.location.replace('/en/domain/view/' + query);
          break;
        case "Contact":
          $form.attr('action', '/en/contact/search/');
          $input.attr('name', 'contactAlias');
          break;
        case "Registrar":
          $form.attr('action', '/en/registrar/search/');
          $input.attr('name', 'registrarAlias');
          break;
      }
    }else if (/^.+\.eu$/i.test(query)) {
      $form.attr('action', '/en/domain/search/');
      $input.attr('name', 'name');
    } else if (/^c[0-9]+$/i.test(query)) {
      $form.attr('action', '/en/contact/search/');
      $input.attr('name', 'contactAlias');
    } else if (/^a[0-9]+$/i.test(query)) {
      $form.attr('action', '/en/registrar/search/');
      $input.attr('name', 'registrarAlias');
    } else if (/^[0-9]+$/i.test(query)) {
      $form.attr('action', '/en/registrar/search/');
      $input.attr('name', 'registrarAlias');
    } else {
      $form.attr('action', '/en/domain/search/');
      $input.attr('name', 'name');
    }
  });
});

nifty.document.ready(function () {
  let $input = $("#omni-query-input");
  let $form = $input.parents("form");

  let category_icon = {
    'Registrar': 'fa-university',
    'Domain': 'fa-globe',
    'Contact': 'fa-users'
  };

  $.widget( "custom.catcomplete", $.ui.autocomplete, {
    _create: function() {
      this._super();
      this.widget().menu( "option", "items", "> :not(.ui-autocomplete-category)" );
    },
    _renderMenu: function( ul, items ) {
      ul.addClass("dropdown-menu omni-query-autocomplete");
      var that = this,
          currentCategory = "";
      if (items.length == 1 && items[0].category == 'no-result'){
        ul.append( "<li class='ui-autocomplete-category'>No results</li>" );
        return;
      }
      if (items.length == 1 && items[0].category == 'not-available'){
        ul.append( "<li class='ui-autocomplete-category'>Autocomplete not available</li>" );
        return;
      }
      $.each( items, function( index, item ) {
        var li;
        if ( item.category != currentCategory ) {
          ul.append( "<li class='ui-autocomplete-category'><i class='fa-solid "+ category_icon[item.category] +"'></i>" + item.category + "s</li>" );
          currentCategory = item.category;
        }
        li = that._renderItemData( ul, item );
        if ( item.category ) {
          li.attr( "aria-label", item.category + " : " + item.label );
        }
      });
    },
    _renderItem: function( ul, item ) {
      switch (item.category){
        case 'Registrar':
          return $( "<li>")
              .append(
                  $("<a>")
                  .text(item['registrar_account_organisation'] + ' - ')
                  .append($('<span class="text-xs text-thin"/>').text(item['registrar_account_alias']))
              )
              .appendTo(ul);
        case 'Contact':
          return $( "<li>" )
              .append(
                  $("<a>")
                  .text(item['contact_name'] + ' - ')
                  .append($('<span class="text-xs text-thin"/>').text(item['contact_alias']))
              )
              .appendTo(ul);
        default:
          return $( "<li>" ).append($("<a>").text( item.value)).appendTo( ul );
      }
    }
  });
  $input.catcomplete({
    delay: 300,
    source: '/en/json/search/autocomplete/',
    response: function(event, ui) {
      // ui.content is the array that's about to be sent to the response callback.
      if (ui.content.length === 0) {

        var noResult = { category:"no-result", value:"",label:"No results found" };
        ui.content.push(noResult);
      } else {
        $("#empty-message").empty();
      }
      $input.parent().find('button i')
          .addClass('fa-magnifying-glass')
          .removeClass('fa-spinner')
          .removeClass('fa-spin')
          .removeClass('text-white');
    },
    select: function(event, ui) {
      $input.val(ui.item.label);
      $form.attr('data-category', ui.item.category);
      $form.submit();
    },
    // eslint-disable-next-line no-unused-vars
    search: function (event, ui) {
      $input.parent().find('button i')
          .removeClass('fa-magnifying-glass')
          .addClass('fa-spinner')
          .addClass('fa-spin')
          .addClass('text-white');
    }
  });
});
