import {doIframe} from "../../../vendor/js/jquery.autoHeight";

nifty.document.ready(function () {
    nifty.document.on('click', '.iframe-select', function () {
        let $rvl = window.top.$('#return-value-container');
        var target = window.top.$('#return-value-container').val();
        var value = $(this).data('value');
        var hash = decodeURIComponent(window.location.hash);
        var key = hash && hash.match(/#select_key-(.+)/)[1];
        window.top.$("#iframe").attr('src', 'about:blank');
        window.top.$("#modal").modal('hide');

        if($rvl.attr('binding')){
            let inputVal = JSON.parse($rvl.val());
            $.each(value, (e)=>{
                // eslint-disable-next-line security/detect-object-injection
                window.top.$('#' + inputVal[e]).val(value[e]);
            });
        }
        else{
            if(target)
                window.top.$('#' + target).val(value);
        }
        window.top.$('body').trigger('nrp.iframeselect', {'key': key, 'value': value});
    });

    // Opens .iframed link in a modal iframe
    nifty.document.on('click', '.iframed', function () {
        if($('#iframe').hasClass('autoHeight')){
            $('#iframe').on('load', function(){
                // doIframe() is from jquery autoHeight
                // eslint-disable-next-line no-undef
                window.setTimeout(function(){doIframe()}, 500);
            })
        }
        $("#iframe").attr('src', $(this).attr('href'));
        $('#modal').addClass($(this).data('addModalClass'));
        $("#modal").modal('show');
        return false;
    });

    // On modal hiding, set the iframe url to blank
    $('#modal').on('hide.bs.modal', function () {
        if ($("#iframe").length) {
            $("#iframe").attr('src', 'about:blank');
        }
    });

    // We use location.hash in iframes to specify the selection target input
    var hash = window.location.hash;
    if (typeof(hash) !== "undefined" && hash !== null) {
        $("ul.pagination li a:not([href*='#'])").each(function (i, v) {
            $(v).attr('href', $(v).attr('href') + hash);
        });
        $("ul.pagination li a[href='#']").each(function (i, v) {
            $(v).attr('href', $(v).attr('href') + hash.substring(1));
        });
    }

    nifty.document.on('click', '.field-search-btn', function () {
        nifty.body.append('<input type="hidden" name="return-value-container" id="return-value-container"/>');
        var $rvl = $("#return-value-container");
        var $input = $(this).closest('.input-group').find('input');
        var $iframe = $("iframe#iframe");
        var iframe_url = $input.attr('data-picker-url');
        var iframe_url_qs = $input.attr('data-picker-qs');
        let iframe_multiple = $input.attr('data-picker-multiple');
        let iframe_binding = $input.attr('data-picker-bind-value-to-field');
        // If iframe_url_qs is a function (it should be defined as a function returning a string), call it and get the string
        // eslint-disable-next-line security/detect-object-injection
        if (typeof window[iframe_url_qs] == 'function') {
            // eslint-disable-next-line security/detect-object-injection
            iframe_url_qs = window[iframe_url_qs]();
        }
        var iframe_select_key = $input.attr('data-picker-select-key');

        if (typeof(iframe_url) !== "undefined" && iframe_url !== null) {
            if (typeof(iframe_url_qs) !== "undefined" && iframe_url_qs !== null)
                iframe_url += '?' + iframe_url_qs;

            if (typeof(iframe_select_key) !== "undefined" && iframe_select_key !== null)
                iframe_url += '#select_key-' + iframe_select_key;

            $iframe.attr("src", iframe_url);
            $("#modal").modal('show');
            if(iframe_multiple && iframe_binding){
                $rvl.val(iframe_binding);
                $rvl.attr('binding', true);
            }
            else{
                $rvl.val($input.attr('id'));
                $rvl.removeAttr('binding');
            }

        }
    });
});