/* Created by hugos */

(function( window, $ ) {

    $.fn.CountUp = function(method, endVal, opts) {
        let args = [].slice.call(arguments);
        let instance;
        let el = this[0];
        let end = $(el).data('end-val')===undefined?endVal:$(el).data('end-val');
        let options = {
            'startVal': $(el).data('start-val')===undefined?(opts.startVal?opts.startVal:0):$(el).data('start-val')
        };


        instance = new CountUp(el, end, options);
        instance.d = $(el);
        switch (method){
            case "start":
                if (!instance.error) {
                    instance.start();
                } else {
                    console.error(instance.error);
                }
                break;
            case "update":
                //check whether data-endVal is different otherwise take second argument
                var num = $(el).data('end-val')===undefined || $(el).data('end-val')===instance.endVal?args[1]:$(el).data('end-val');
                if(num!==undefined){
                    instance.update(num)
                }
                break;
            case "pauseResume":
                instance.pauseResume();
                break;
            case "reset":
                instance.reset();
                break;
        }


		return this;
    };

})(window, jQuery);