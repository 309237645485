function buildListWidget($widget) {
    let v = $widget.find('input.field_value').val();
    let l = v.split(',');
    $widget.find('.list-group-list').empty();
    let empty = true;
    for (let i in l) {
        // eslint-disable-next-line security/detect-object-injection
        if ($.trim(l[i]) == '') {
            continue;
        }
        empty = false;
        $widget.find('.list-group-list').append(
            '<span class="list-group-item list-item-sm">'
            // eslint-disable-next-line security/detect-object-injection
            + '<a href="javascript:void(0);" data-value="' + escapeHtml(l[i]) + '" class="fa-solid fa-xmark text-danger btn-remove mar-rgt"></a>' + l[i]
            + '</span>'
        )
    }
    if (empty) {
        $widget.find('.list-group-list').append(
            '<span class="list-group-item list-item-sm bord-no text-thin bg-gray-light text-center text-sm">Empty list</span>');
    }
}

function escapeHtml(unsafe) {
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
 }

function buildListErrorWidget($widget){
    $.each($widget.parent().find('span.carret-error'), function(ind, el){
        let attr = $(el).next('.error-tooltip').attr('data-field-ind');
        if(typeof attr !== typeof undefined && attr !== false)
            $(el).remove();
    });
    $.each($widget.parent().find('.error-tooltip'), function(ind, el){
        let attr = $(el).attr('data-field-ind');
        if(typeof attr !== typeof undefined && attr !== false){
            let index = parseInt(attr.split('[').slice(-1)[0].split(']')[0]);
            /* eslint-disable security/detect-object-injection */
            $($widget.find('.list-group-list .list-group-item')[index]).append(el);
            $($widget.find('.list-group-list .list-group-item')[index]).css("padding-bottom", "10px");
            $($widget.find('.list-group-list .list-group-item')[index]).css("padding-top", "10px");
            /* eslint-enable security/detect-object-injection */
        }
    });
}

nifty.document.ready(function () {
    $('.field-with-extension').each(function () {

        if (!$(this).find('input').data('greek-extension') && !$(this).find('input').data('cyrillic-extension')) {
            $(this).find('.dropdown-toggle').remove();
            $(this).removeClass('input-group');
        }
        if (!$(this).find('input').data('cyrillic-extension')) {
            $(this).find('a.cyrillic-extension').remove();
        }
        if (!$(this).find('input').data('greek-extension')) {
            $(this).find('a.greek-extension').remove();
        }
        if ($(this).find('input').data('empty-extension') == 'disabled') {
            $(this).find('a.empty-extension').remove();
        }
        let currentText = $(this).find('input').val();
        let currentExtension = '';
        let m = currentText.match(/\.(.){2}$/gi)
        if (m) {
            currentExtension = m[0];
        }
        selectExtension($(this), currentExtension)
    });

    $('.input-list-widget').each(function () {
        buildListWidget($(this));
        buildListErrorWidget($(this));
    });

    $('.input-list-widget .add_value').bind('keypress', function (e) {
        if (e.which == 13) {
            e.preventDefault();
            let $widget = $(this).parentsUntil('.input-list-widget');
            $widget.find('.btn-add').click();
        }
    });
});

$('body').on('click', '.input-list-widget .btn-add', function () {
    let $widget = $(this).parentsUntil('.input-list-widget');
    let $input = $widget.find('.add_value');
    let $field_value = $widget.find('input.field_value');
    let v = $field_value.val();
    let l = v.split(',');
    if ($.trim($input.val()) == "" || $.inArray($.trim($input.val()), l) != -1) {
        return false;
    }
    if (l.length == $field_value.data('token-max')) {
        alert('Maximum number of elements reached');
        return false;
    }
    l.push($input.val());
    $input.val("");
    $field_value.val(l.join(','));
    buildListWidget($widget);
});
$('body').on('click', '.input-list-widget .btn-remove', function () {
    let $widget = $(this).parentsUntil('.input-list-widget');
    let value_to_remove = $(this).data('value');
    let $field_value = $widget.find('input.field_value');
    let v = $field_value.val();
    let l = v.split(',');
    let new_l = [];
    for (let i in l) {
        // eslint-disable-next-line security/detect-object-injection
        if (l[i] != value_to_remove) {
            // eslint-disable-next-line security/detect-object-injection
            new_l.push(l[i]);
        }
    }
    $field_value.val(new_l.join(','));
    buildListWidget($widget);
});

$('body').on('submit', 'form:has(.input-list-widget)', function () {

    $(this).find('.input-list-widget').each(function(){
        let $widget = $(this);
        let $field_value = $widget.find('input.field_value');
        let $input = $widget.find('.add_value');
        if ($.trim($input.val()) !== "") {
            let v = $field_value.val();
            let l = v.split(',');
            if ($.trim($input.val()) == "" || $.inArray($.trim($input.val()), l) != -1) {
                return false;
            }
            if (l.length == $field_value.data('token-max')) {
                return false;
            }
            l.push($input.val());
            $input.val("");
            $field_value.val(l.join(','));
            buildListWidget($widget);
        }
    })
});

$('body').on('click', '.field-with-extension .dropdown-menu a', function (e) {
    e.preventDefault();
    let $input = $(this).parentsUntil('.field-with-extension').parent().find('input');
    let currentText = $.trim($input.val());
    let selectedExtension = $(this).attr('data-value');
    if (currentText.match(/\.(.){2}$/gi)) {
        $input.val(currentText.replace(/\.(.){2}$/gi, selectedExtension));
    } else {
        $input.val(currentText + selectedExtension);
    }
    if ($input.val() == selectedExtension) {
        $input.focus().setCursorPosition(0);
    }
    selectExtension($(this).parentsUntil('.field-with-extension').parent(), selectedExtension);
});

$('body').on('input propertychange paste', '.field-with-extension input', function () {
    let that = this;
    let changed = false;
    $.each(getExtensions(), function (i, v) {
        if ($.trim($(that).val()).endsWith(v)) {
            selectExtension($(that).parent(), v);
            changed = true;
        }
    });
    if (!changed) {
        selectExtension($(that).parent(), '')
    }
});

$('.checkall').on('click', function () {
    let on_off = this.checked ? "toggleOn" : "toggleOff";
    $(this).closest('fieldset').find('.form-checkbox').niftyCheck(on_off);
});

function selectExtension($field, extension) {
    let hasNight = $('body').hasClass('night');
    $field.find('.extension-dropdown a').each(function () {
        if ($(this).data('value') == extension) {
            $(this).addClass(hasNight ? "bg-night" :"bg-gray");
            $field.find('button>span.ext').text($(this).text());
        } else {

            $(this).removeClass(hasNight ? "bg-night" :"bg-gray");
        }
    });
}

let all_extensions = [];

function getExtensions() {

    if (all_extensions.length === 0) {
        $('.extension-dropdown a').each(function () {
            let val = $(this).attr('data-value');
            if (val.length > 0) {
                all_extensions.push(val)
            }
        })
    }
    return all_extensions;
}

// Restricts input for the given textbox to the given inputFilter function.
(function($) {
  $.fn.inputFilter = function(inputFilter) {
    return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (Object.prototype.hasOwnProperty.call(this, "oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = "";
      }
    });
  };
}(jQuery));